.welcome_modal {
  width: 60%;
  height: max-content !important;
  .welcome_modal_header {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7vh;
    padding: 3rem 4rem !important;
    .step-number {
      color: #2e74f3;
      .step-total {
        font-size: 12px;
        margin-right: 10px;
      }
      .step-title {
        color: #000000;
      }
    }
  }
  .welcome_modal_header, .welcome_modal_body, .welcome_modal__buttons--footer {
    padding: 2.4rem 4rem;
  }
  
  .welcome_modal_body {
    .welcome-container {
      padding-top: 20px;
      display: grid;
      gap: 3rem;
      justify-items: center;
      text-align: center;
      .welcome-text {       
        color: #0e3050;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .note_header {
      color: #2e74f3;
      font-weight: bold;
      margin-top: 15px;
    }
    .note_content {
      font-size: 14px;
    }
    .settings-header {
      font-weight: 500;
      margin-bottom: 20px;
      .setting-highlight {
        color: #2e74f3;
      }
    }
  }

  .welcome_modal__buttons--footer {
    padding-top: 0;
    display: flex;
    justify-content: flex-end;
  }

}

// Loading
.loading {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}