@import '../../styles/import';

.error_modal {
  height: 33%;
  width: 57%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 4rem;

  &__body {
    padding-left: 4rem;
  }
  &__icon-box {
    display: flex;
    color: $brand-danger;
    font-size: map-get($font-sizes, 'small');
    font-weight: map-get($font-weights, 'bold');
    padding: 2.4rem 4rem;
    align-items: center;
  }
  &__icon {
    height: 3rem;
    width: 3rem;
    position: relative;
    top: 5px;
  }
  &__message {
    padding-top: 2.4rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {
    padding: 0;
    width: 13.6rem;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    font-weight: map-get($font-weights, 'bold');
  }
}
