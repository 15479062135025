@import '../../styles/import';

.charts-widget {
  height: 100%;
  width: 100%;

  @include flex();
  @include break(map-get($grid-breakpoints, lg)) {
    @include flex($dir: column);
  }

  &__left-side {
    flex: 4 1 0;
    @include flex($dir: column);
    color: $color-black;
    position: relative;
    padding: 2.4rem;
    border-right: 1px solid $color-lightest-gray;

    @include break(992px) {
      border: none;
    }
  }

  &__heading {
    font-family: $font-commissioner;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-bottom: 1.2rem;
  }

  &__count {
    font-family: $font-sans-pro;
    font-weight: 500;
    font-size: 3.8rem;
    line-height: 4.6rem;
  }

  &__left-chart {
    flex: 1;
  }

  &__separator {
    flex: 0 0 1px;
    background-color: rgba($color-gray-light, 0.5);
  }

  &__right-side {
    flex: 7 1 0;
    padding: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__right-chart {
    margin: 0 auto;
    width: fit-content;
  }
}

.recharts {
  &-legend-item {
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 2px;
    margin-right: 0px;
    margin-bottom: 2.2rem;
  }
  &-legend-item.legend-item-0 {
    margin-top: 2rem;
  }

  &-legend-item-text span {
    margin-block: 0rem !important;
    margin-right: 1.2rem !important;
  }
  &-surface {
    margin-left: 0.9rem !important;
  }
}
