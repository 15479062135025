@import '../../styles/import';

.confirmation {
  background-image: url('../../assets/confirmation_page_lines.png'), url('../../assets/eclipse.png');

  background-position: center, center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: $font-commissioner;

  &__card {
    background: $background-color-base;
    box-shadow: 0 0.4rem 2.4rem $drop-shadow--primary;
    width: 80vh;
    max-width: 70%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6.4rem;
  }
  &__image {
    width: 59%;
  }
  &__image--email {
    height: 100%;
    width: 100%;
  }
  &__content {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1.6rem;
  }
  &__heading {
    font-weight: map-get($font-weights, 'bold');
    font-size: map-get($font-sizes, 'medium');
    line-height: map-get($line-heights, 'medium');
    color: $font-color-base;
    margin-top: 3.2rem;
    margin-bottom: 2rem;
  }
  &__subtext {
    font-weight: map-get($font-weights, 'normal');
    color: $component-text-inactive;
    line-height: 2.5rem;
    font-size: map-get($font-sizes, 'mini');
    padding: 0 2rem 8rem 2rem;
  }
  &__subtext--bold {
    font-weight: map-get($font-weights, 'medium');
  }
  &__footer-text-container {
    margin-top: 4vh;
    font-weight: map-get($font-weights, 'normal');
    font-size: map-get($font-sizes, 'micro');
    padding: 0 2rem;
    text-align: center;
    color: $color-gray;
  }

  &__icon__container {
    display: inline-block;
    margin-left: 0.6rem;
  }

  &__icon {
    display: flex;
    height: 2.4rem;
    width: 2.4rem;
    position: relative;
    top: 1rem;
  }

  &__button {
    text-decoration: none;
    background-color: white;
    border: none;
  }
  button[class='confirmation__button']:not([disabled]):hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &__button-text {
    color: $color-black;
    text-decoration: none;
    &--ml-5 {
      margin-left: 0.5rem;
    }
  }

  /* Media Queries */

  @include break(map-get($grid-breakpoints, sm)) {
    background-image: none;
    background-position: center, center;
    &__subtext {
      padding: 0 2rem 4rem 2rem;
    }
    &__card {
      min-width: 80%;
    }
  }
}

.resend_mail_modal {
  height: 33%;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.4rem;

  &__header {
    display: flex;
    justify-content: center;
    font-size: map-get($font-sizes, 'small');
    font-weight: map-get($font-weights, 'bold');
    padding: 5.4rem 4rem;
  }

  &__footer {
    display: flex;
    justify-content: center;
    gap: 2.4rem;
    margin-bottom: 4rem;
  }

  &__footer-button {
    padding: 0;
    width: 13.6rem;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    font-weight: map-get($font-weights, 'bold');
  }
}
