@import '../../styles/import';

.icon-btn {
  @include flex($jc: center, $ai: center);
  color: $color-gray-darker;
  cursor: pointer;
  transition: transform 100ms ease-out;

  &--square {
    border-radius: 0.8rem;
    height: 4.8rem;
    width: 4.8rem;
    background-color: transparent;
  }

  &--small_square {
    border-radius: 0.8rem;
    height: 3.5rem;
    width: 3.5rem;
    background-color: transparent;
  }

  &--round {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-color: $color-white;
    border: 1px solid $color-gray-light;
  }

  &--disabled {
    color: $color-gray-light;
  }

  &--active {
    color: $color-white;
    background-color: $color-primary-dark;
  }

  &:active:not([class~='icon-btn--disabled']) {
    transform: scale(0.9);
  }

  &__icon-box {
    display: block;
  }

  &__icon {
    height: 2.6rem;
    width: 2.6rem;
    display: block;
  }
}



.icon-button-active {
  background-color: $bright-blue-light;
  border-radius: 4px;
}