@import '../../styles/import';

.btn_dropdown {
  @include respond(tab-portrait) {
    display: none;
  }

  position: relative;
  @include flex($dis: inline-block);
  // min-width: 22rem;
  width: 100%;
  font-family: $font-commissioner;

  &__box {
    padding: 0 1rem;
    @include flex($dis: flex, $jc: space-between, $ai: center);
    border-radius: 0.6rem;
    cursor: pointer;
    background: none !important;
  }

  &__selection-text {
    font-size: 1.6rem;
    line-height: map-get($line-heights, 'medium');
    color: $color-gray-darker;
    @include flex($dis: flex, $dir: row, $ai: center);

    span {
      margin-right: 1rem;
      font-weight: map-get($font-weights, 'medium');
    }
    p {
      margin: 0 1rem;
      color: $color-gray-label;
      font-size: map-get($font-sizes, 'micro');
      font-weight: map-get($font-weights, 'light');
    }
  }
}
