@import '../../styles/import';
.modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($color: #000000, $alpha: 0.4);
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;

  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 30;
  }

  &__content {
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    position: relative;

    opacity: 0;
    visibility: hidden;

    height: 92vh;
    width: 95vw;
  }

  &.active &__content {
    opacity: 1;
    visibility: visible;
  }

  &__header {
    font-size: map-get($font-sizes, 'small');
    font-weight: map-get($font-weights, 'bold');
  }
  &__footer {
    margin-top: auto;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 20px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #7f7f7f;

    &:hover {
      color: #000;
    }
  }
}
