.width {
  &__10 {
    width: 10%;
  }
  &__20 {
    width: 20%;
  }
  &__25 {
    width: 25%;
  }
  &__30 {
    width: 32.5%;
  }
  &__40 {
    width: 40%;
  }
  &__50 {
    width: 50%;
  }
  &__60 {
    width: 58%;
  }
  &__70 {
    width: 70%;
  }
  &__80 {
    width: 80%;
  }
  &__90 {
    width: 90%;
  }
  &__100 {
    width: 100%;
  }
}
.height {
  &__10 {
    height: 10vh;
  }
  &__20 {
    height: 20vh;
  }
  &__25 {
    height: 25vh;
  }
  &__30 {
    height: 30vh;
  }
  &__40 {
    height: 40vh;
  }
  &__50 {
    height: 50vh;
  }
  &__60 {
    height: 60vh;
  }
  &__70 {
    height: 70vh;
  }
  &__80 {
    height: 80vh;
  }
  &__90 {
    height: 90vh;
  }
  &__100 {
    height: 100vh;
  }
}
.--left {
  &__15 {
    left: 15%;
  }
  &__40 {
    left: 42%;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
}
.vertical-divider {
  height: 100%;
  width: 1px;
  background-color: #e0e0e0;
  position: absolute;
  top: 0;

  @include break(map-get($grid-breakpoints, lg)) {
    display: none;
  }
}

.margin {
  &__1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  &__2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  &__3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  &__4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  &__5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}
