@import '../../styles/import';
@import '../../styles/main';

.dashboard {
  width: 100%;
  &__new_config {
    position: absolute;
    top: 5rem;
    left: 7rem;
    height: 80vh;
    width: 90vw;
  }
  &__link {
    text-decoration: none;
  }
}

.configuration__dropdown {
  min-width: 230px;
  width: max-content;
  padding-left: 3rem;
  position: relative;
  // position: sticky;
}

.flex-class {
  display: flex;
  gap: 10px;
}

.center-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-width {
  // width: 400px;
  width: 150px;
  justify-content: center;
}

.padding-btm-3 {
  padding-bottom: 3rem;
}

.loading-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.padding-left-3 {
  padding-left: 3rem;
}
.padding-title {
  padding: 1.8rem 3rem;
}
.padding-x-none {
  padding: 0;
}

.border-bottom-none {
  border-bottom: none;
}

.padding-top-3 {
  padding-top: 3rem;
}

.height-max {
  height: max-content;
}