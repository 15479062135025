@import '../../styles/import';

.home-page {
  padding: 4rem 0 2rem 0;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__content {
    display: flex;
    background: transparent;
  }

  &__heading {
    font-family: $font-inter;
    color: $font-color-base;
    font-size: map-get($font-sizes, 'biggest');
    line-height: map-get($line-heights, 'biggest');
    font-weight: map-get($font-weights, 'bold');
  }

  &__caption {
    font-size: map-get($font-sizes, 'smallest');
    line-height: map-get($line-heights, 'smallest');
    padding: 1.6rem 0;
    margin-bottom: 2rem;
    color: $font-color-secondary;
  }
  &__textbox {
    width: 82%;
    margin-bottom: 2.4rem;
  }

  &__recaptcha {
    margin-bottom: 5.6rem;
  }

  &__left {
    width: 48%;
  }
  &__right {
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    width: 52%;
    left: 10rem;
    bottom: 1rem;
  }

  @include break(map-get($grid-breakpoints, lg)) {
    padding: 3rem 0;
    &__heading {
      font-size: map-get($font-sizes, 'bigger');
      line-height: map-get($line-heights, 'bigger');
    }
  }

  @include break(map-get($grid-breakpoints, md)) {
    &__content {
      flex-direction: column-reverse;
      position: relative;
      overflow: hidden;

      left: 0rem;
      bottom: 0rem;
    }

    &__left {
      width: 100%;
    }
    &__right {
      position: initial;
      width: 100%;
      &-img {
        position: relative;
        width: 100%;
      }
    }
  }

  @include break(map-get($grid-breakpoints, sm)) {
    padding: 2rem 0;
    &__heading {
      font-size: map-get($font-sizes, 'big');
      line-height: map-get($line-heights, 'big');
    }
    &__caption {
      font-size: map-get($font-sizes, 'mini');
    }
    &__textbox {
      width: 100%;
    }

    &__right {
      display: none;
    }
  }

  @include break(342px) {
    &__recaptcha {
      transform: scale(0.9);
      transform-origin: 0 0;
    }
  }
}
