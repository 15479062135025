@import '../../styles/import';

.login-page {
  &__wrapper {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
  }
  &__container {
    padding: 0 5rem;
  }
  &__common {
    min-height: 100vh;
    overflow-x: hidden;
    &__bg-top {
      position: relative;
      z-index: -1;
      &::after {
        background-color: lighten($color-light-blue, 30%);
        border-radius: 50%;
        content: '';
        height: 46.8rem;
        position: absolute;
        width: 46.8rem;
        top: -19.8rem;
        right: -16.1rem;
      }
    }
    &__bg-btm {
      bottom: 0;
      height: 45rem;
      overflow: hidden;
      position: absolute;
      width: 100%;
      z-index: -1;
      &_cr {
        background-color: rgba($color-dark-cyan, 0.4);
        border-radius: 50%;
        content: '';
        height: 70rem;
        position: absolute;
        width: 70rem;

        &--circle-left {
          top: 0;
          left: -46rem;
        }
        &--circle-right {
          top: 13.5rem;
          left: -21.9rem;
        }
      }
    }
  }
  @include break(map-get($grid-breakpoints, sm)) {
    &__container {
      padding: 0 2rem;
    }
    &__common {
      &__bg-top {
        &::after {
          height: 36.8rem;
          width: 36.8rem;
        }
      }
      &__bg-btm {
        height: 35rem;
        &_cr {
          height: 40rem;
          width: 40rem;

          &--circle-left {
            top: 0;
            left: -30rem;
          }
          &--circle-right {
            top: 13.5rem;
            left: -11.9rem;
          }
        }
      }
    }
  }
}

.sc-page {
  &__wrapper {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
  }
  &__container {
    .page-container {
      display: flex;
      padding-top: 24px;
      flex-direction: row;
      background-color: $background-color-primary;
      min-height: 93vh;

      @include respond(tab-portrait) {
        padding-top: 1rem;
      }
    }
  }
  &__common {
    min-height: 100vh;
    // overflow-x: hidden;
    &__bg-top {
      position: relative;
      z-index: -1;
      &::after {
        background-color: lighten($color-light-blue, 30%);
        border-radius: 50%;
        content: '';
        height: 46.8rem;
        position: absolute;
        width: 46.8rem;
        top: -19.8rem;
        right: -16.1rem;
      }
    }
  }
  @include break(map-get($grid-breakpoints, sm)) {
    &__container {
      // padding: 0 2rem;
    }
  }
}

.widget {
  &__body {
    @include flex($dis: flex, $dir: column);
  }
  &__header {
    display: flex;
  }
  &__title {
    font-size: map-get($font-sizes, 'small');
    font-weight: map-get($font-weights, 'medium');
    margin-bottom: 1.6rem;
    width: 50%;
    &::before {
      content: url('../../assets/title_triangle.svg');
      position: relative;
      bottom: -5px;
      left: -4px;
    }
  }
  &__subtitle {
    width: 50%;
    font-size: map-get($font-sizes, 'micro');
    color: $color-gray;
    display: flex;
    align-items: center;
  }
  &__row {
    @include flex($dis: flex, $jc: space-between);
    width: 100%;
    margin-top: 1.6rem;
  }
  &__row-left-entry {
    width: 50%;
    overflow: hidden;
    word-break: break-all;
    padding-right: 2rem;
  }
  &__row-right-entry {
    width: 50%;

    div {
      width: 100%;
      @include flex($dis: flex, $jc: flex-start, $ai: center);
    }
  }
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.w-h-16-icon {
  cursor: default;
  .icon-btn__icon  {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.failed-text {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0.4rem 0;
}
