@import '../../styles/import';
.sc-header {
  display: flex;
  justify-content: space-between;
  padding: 36px 0;
  &__logo {
    align-self: center;
    &-img {
      display: none;
    }
  }
  .button {
    &.--login {
      padding: 1.1rem 3.7rem;
      align-self: center;
    }
  }

  @include break(map-get($grid-breakpoints, sm)) {
    &__logo {
      svg {
        display: none;
      }
      &-img {
        display: inline-block;
      }
    }
  }
}
