@import '../../styles/import';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button {
  border-radius: 0.8rem;
  border-style: solid;
  border-width: 1px;
  font-family: $font-commissioner;
  display: flex;
  gap: 0.8rem;
  font-weight: map-get($font-weights, 'normal');
  font-size: map-get($font-sizes, 'mini');
  line-height: map-get($line-heights, 'mini');
  cursor: pointer;
  padding: 1.6rem 8rem;
  text-transform: capitalize;

  &__icon-box {
    display: inline-block;
    margin-top: 5px;
  }

  &__icon {
    display: flex;
    height: 2.4rem;
    width: 2.4rem;
  }
  &--xnano{
    font-size: map-get($font-sizes, 'micro');
    padding: 0.8rem 1rem;
  }

  &--nano {
    font-size: map-get($font-sizes, 'micro');
    padding: 0.8rem 1.6rem;
  }

  &--micro {
    font-size: map-get($font-sizes, 'micro');
    padding: 0.8rem 2.6rem;
  }
  &--xsmall {
    font-size: map-get($font-sizes, 'micro');
    padding: 0.8rem 3.6rem;
  }
  &--small {
    font-size: map-get($font-sizes, 'micro');
    padding: 1.4rem 7rem;
  }

  &--normal {
    font-size: map-get($font-sizes, 'mini');
    padding: 1.6rem 8rem;
  }

  &--medium {
    font-size: map-get($font-sizes, 'smallest');
    padding: 1.8rem 9rem;
  }

  &--large {
    font-size: map-get($font-sizes, 'smaller');
    padding: 2rem 10rem;
  }

  &--primary {
    background-color: $button-color--primary;
    border-color: $button-color--primary;
    color: $color-white;
    &:hover {
      background-color: $button-color--primary--hover;
      border-color: $button-color--primary--hover;
    }
    &:active {
      background-color: $button-color--primary--active;
      border-color: $button-color--primary--active;
    }
  }

  &--primary-disabled {
    background-color: $button-color--disabled;
    color: $color-white;
    cursor: not-allowed;
  }

  &--secondary {
    color: $brand-primary;
    background-color: $button-color--secondary;
    border-color: $button-color--secondary-bdr;
    &:hover {
      background-color: $button-color--secondary--hover;
      border-color: $button-color--secondary-bdr--hover;
    }
    &:active {
      background-color: $button-color--secondary--active;
      border-color: $button-color--secondary-bdr--active;
    }
  }

  &--secondary-disabled {
    background-color: $color-white;
    border-color: darken($button-color--disabled, 30%);
    color: $color-gray-light;
    cursor: not-allowed;
  }

  &--tertiary {
    color: $brand-primary;
    border: 0;
    background-color: $button-color--tertiary;
    border-color: $button-color--tertiary-bdr;
    // &:hover {
    //   background-color: $button-color--tertiary--hover;
    //   border-color: $button-color--tertiary-bdr--hover;
    // }

    & .button__text {
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        display: inline-block;
        background-color: currentColor;
        width: 0%;
        height: 1px;
        transition: width 100ms ease-out;
      }
    }

    &:hover .button__text::after {
      width: 100%;
    }

    &:active {
      background-color: $button-color--tertiary--active;
      border-color: $button-color--tertiary-bdr--active;
    }
  }

  &--tertiary-disabled {
    background-color: $color-white;
    border: 0;
    border-color: darken($button-color--disabled, 30%);
    color: $color-gray-light;
    cursor: not-allowed;
  }

  &--loading {
    display: block;
    position: absolute;
    border: 4px solid lightgray;
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: lightgray lightgray lightgray transparent;
    animation-delay: -0.3s;
  }
}

.relative {
  position: relative;
}


