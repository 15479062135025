@import '../../styles/import';

.abort_modal {
  height: 37%;
  width: 57%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.4rem;

  &__header {
    display: flex;
    justify-content: flex-start;

    font-size: map-get($font-sizes, 'small');
    font-weight: map-get($font-weights, 'bold');
    padding: 2.4rem 4rem;
  }

  &__icon {
    height: 2rem;
    width: 3rem;
  }

  &__body {
    padding: 2.4rem 4rem;
    color: $font-color-secondary;
  }

  &__body-text {
    margin-bottom: 12px;

    font-size: map-get($font-sizes, 'smaller');
    font-weight: map-get($font-weights, 'bold');
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 2.4rem;
    margin-right: 4rem;
    margin-bottom: 4rem;
  }

  &__footer-button {
    padding: 0;
    width: 13.6rem;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    font-weight: map-get($font-weights, 'bold');
  }
}
