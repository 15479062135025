@import '../../styles/import';
.settings {
  font-size: 1.8rem;
  background-color: $color-white;
  margin-top: 1rem;
  padding: 2.4rem;
  border-radius: 0.4rem;
  border: 1px solid $color-lightest-gray;
  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2.4rem;
    margin-bottom: 3.2rem;
  }
  &__row-container {
    max-height: 65vh;
    min-height: 23vh;
    overflow-y: auto;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 2.4rem;
    margin-top: 2rem;
  }
  &__select {
    width: 24%;
    margin-bottom: 2.5rem;
  }
  &__pattern {
    width: 66%;
    position: relative;
    top: -5px;
    .input__field {
      background: none !important;
    }
    @include break(map-get($grid-breakpoints, sm)) {
      & {
        margin-left: 3.3rem;
      }
    }
  }

  &-dropdown {
    height: 5rem;
    min-width: -moz-fit-content;
    min-width: fit-content;

    .dd-cont__selection-box {
      border: 1px solid #bdbdbd;
      border-radius: 4px;
      height: 5rem;
      max-height: 5rem;
    }
    .dd-cont__selection-text span {
      font-weight: 400;
    }
    @include break(map-get($grid-breakpoints, sm)) {
      .dd-cont__selection-box {
        width: 9rem;
      }
    }
  }

  &__icon--delete {
    margin-top: 3rem;
  }
  &__button {
    padding: 0;
    width: 13.6rem;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    font-weight: map-get($font-weights, 'bold');
  }
}

.settings_modal {
  height: 37%;
  width: 57%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.4rem;

  &__header {
    display: flex;
    justify-content: flex-start;

    font-size: map-get($font-sizes, 'small');
    font-weight: map-get($font-weights, 'bold');
    padding: 2.4rem 4rem;
  }
  &__icon {
    height: 2rem;
    width: 3rem;
  }
  &__content {
    padding: 2.4rem 4rem;
    color: $font-color-secondary;
  }
  &__text {
    margin-bottom: 12px;

    font-size: map-get($font-sizes, 'smaller');
    font-weight: map-get($font-weights, 'bold');
  }
  &__subtext {
    font-size: map-get($font-sizes, 'mini');
    font-weight: map-get($font-weights, 'normal');
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 2.4rem;
    margin-right: 4rem;
    margin-bottom: 4rem;
  }

  &__button {
    padding: 0;
    width: 13.6rem;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    font-weight: map-get($font-weights, 'bold');
  }

  // .modal__body {
  //   padding: 0 2rem 1.6rem;
  // }
}
