@import '../../styles/import';

.details {
  margin-top: 1.4rem;
  display: block;
  max-width: 100%;
  background-color: $color-white;

  overflow-x: auto;

  &__table {
    width: 100%;
    min-width: 94.5rem;
    border-spacing: 0;

    border-collapse: collapse;
    border: 1px solid $color-lightest-gray;

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid $color-lightest-gray !important;
      border-right: 1px solid $color-lightest-gray !important;
      text-align: left;
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }

    th {
      color: black;
      font-weight: bold;
      padding: 1rem;
    }

    td {
      word-break: break-word;
      padding: 1rem;
    }

    td:nth-child(1) {
      width: 7%;
      text-align: center;
    }

    @include break(map-get($grid-breakpoints, xl)) {
      td:nth-child(1) {
        width: 10%;
      }
    }
  }

  .toggle_columns {
    display: flex;
  }

  &__pageInfo {
    display: flex;
    align-items: center;
  }
  &__previous,
  &__next {
    padding: 5px 30px;
    margin: 20px;
    background: none;
  }
  &__input {
    background: transparent;
    border: none;
    width: 80%;
  }
  &__input:focus {
    outline: none;
  }
  &_page_count {
    width: 5rem !important;
    min-width: fit-content;
    .select--open {
      bottom: 5.6rem;
      top: auto;
    }
  }

  &__title {
    font-size: map-get($font-sizes, 'smallest');
    font-weight: map-get($font-weights, 'medium');

    &::before {
      content: url('../../assets/title_triangle.svg');
      position: relative;
      bottom: -5px;
      left: -4px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    &__child {
      // width: 50%;
      display: flex;
      align-items: center;
      margin: 2rem;
    }

    strong {
      margin: 5px;
    }
  }

  &__text-primary {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: map-get($font-weights, 'semi-bold');
    color: $brand-primary;
  }
  &__icon-container {
    height: 4rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-gray;
    border-radius: 0.4rem;
  }

  &__icon {
    height: 2rem;
    width: 1rem;
    margin-top: 0.8rem;
    fill: $color-gray;
  }
  &__icon-container:hover {
    border-color: $brand-primary;
    fill: $brand-primary;
    .details__icon {
      fill: $brand-primary;
    }
  }

  &__icon-container[disabled] {
    .details__icon {
      fill: #bcbcbc;
    }
    border-color: #bcbcbc;
  }

  .rc-pagination {
    display: flex;
    li {
      height: 4rem;
      width: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-item {
      border: none;
      font-family: $font-commissioner;
      font-weight: map-get($font-weights, 'semi-bold');
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-right: 0;
      border-radius: 0.6rem;
      a {
        color: $color-gray;
      }
    }
    &-item-active {
      text-decoration: underline;
      text-decoration-color: $brand-primary;
      a {
        color: $brand-primary;
      }
    }
  }
}
