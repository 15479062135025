@import '../../styles/import';
@import '../../styles/main';

.summary {
  &__top-row {
    display: flex;
    gap: 1.5rem;
    margin-top: 1.4rem;

    @include respond(tab-landscape) {
      flex-direction: column;
    }
  }

  &__charts {
    flex: 5 1 0;

    padding: 0;
  }

  &__links-wrapper {
    padding: 2.4rem;
  }

  &__links {
    flex: 2 1 0;
    padding: 0;
  }

  &__bottom-row {
    margin-top: 1.8rem;
    margin-bottom: 2.4rem;
    display: flex;
    gap: 1.5rem;

    @include respond(tab-landscape) {
      flex-direction: column;
    }

    @include respond(tab-portrait) {
      margin-bottom: 1rem;
    }
  }

  &__widget {
    flex: 1 1 0;
    position: relative;
    min-height: 24.5rem;
    padding-top: 1.6rem;

    @include break(map-get($grid-breakpoints, lg)) {
      width: 100% !important;
    }
  }

  &__widgets-container {
    // width: 100%;
    // padding-top: 1.4rem;
    // display: flex;
    // flex-wrap: wrap;
    // row-gap: 1.8rem;
    // column-gap: 0.9rem;
    // padding-bottom: 2rem;
  }

  &__widget-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__widget-title {
    font-size: map-get($font-sizes, 'small');
    font-weight: map-get($font-weights, 'medium');
    margin-bottom: 2rem;

    &::before {
      content: url('../../assets/title_triangle.svg');
      position: relative;
      bottom: -5px;
      left: -4px;
    }
  }
  &__widget-subtitle {
    font-size: map-get($font-sizes, 'micro');
    color: $color-gray;
  }

  &__widget-body {
    display: flex;
    flex-direction: row;
  }

  &__widget-body-left {
    @extend .--no-bullets;
    @extend .--flex-horizontal-left;
    flex: 5 1 0;
    overflow: hidden;

    @include break(map-get($grid-breakpoints, lg)) {
      font-size: map-get($font-sizes, 'micro');
    }

    ul li {
      margin-top: 1.6rem;
      display: flex;
      flex-direction: row;
    }
  }
  &__widget-body-right {
    @extend .--flex-horizontal-left;
    @extend .--no-bullets;
    flex: 2 1 0;

    @include break(map-get($grid-breakpoints, lg)) {
      font-size: map-get($font-sizes, 'micro');
    }

    ul li {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 1.6rem;
      span {
        font-weight: 500;
      }
    }
  }

  &__widget-body-left-icon {
    height: 2.2rem;
    width: 2.2rem;
    margin-right: 1rem;
    display: block;
    &.--pointer {
      cursor: pointer;
    }
  }
  &__card-bottom {
    height: 48rem;
    // overflow: auto;
  }
}
