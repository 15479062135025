@import '../../styles/import';

.sh-cont {
  padding: 0 2.5rem;
  @include break(map-get($grid-breakpoints, lg)) {
    font-size: map-get($font-sizes, 'micro') !important;
  }
  &__container {
    min-height: 6rem;
    padding: 0 2.4rem;

    @include respond(tab-portrait) {
      padding: 0 1rem;
    }
  }

  &__domain {
    display: flex;
    align-items: center;
    min-height: 100% !important;
  }

  &__icon-box {
    min-height: 6rem;
  }

  &__icon {
    height: 3.2rem;
    width: 3.2rem;
    display: block;
  }

  &__text {
    font-family: $font-commissioner;
    font-weight: map-get($font-weights, 'semi-bold');
    font-size: $action-header-font-size;
    line-height: map-get($line-heights, 'bigger');
    color: $color-gray-dark;
  }

  &__btn-grp {
    display: flex;
    gap: 1.6rem;

    button {
      min-height: 6rem;
      display: inline-flex;
      align-items: center;
    }
  }

  &__btn-container {
    @include respond(mobile) {
      display: none;
    }
  }
}

// To remove the extra container thats being created next to the three tabs
.rc-tabs-ink-bar.rc-tabs-ink-bar-animated,
.rc-tabs-nav-operations.rc-tabs-nav-operations-hidden {
  display: none;
}
