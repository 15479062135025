.center-align-title {
  display: flex;
  align-items: center;
}

.padding-left-0 {
  padding-left: unset !important;
}

.padding-right-0 {
  padding-right: unset ;
}

.font-18rem {
  font-size: 1.8rem;
}