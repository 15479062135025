/* The approach to name colors in Site Checker is as follows:
First we define variables for the colors by calling them
after their name.
Naming convention:
$color-{what-color-is-it}-{name-of-the-shade}
All the color names chosen in this file are based on this theory:
https://davidwalsh.name/sass-color-variables-dont-suck

==========================================================================
Main colors
========================================================================== */
/** This will be deleteted once we replace new branding in all the component **/
$color-primary-light: #5c54ff;
$color-primary-dark: #292582;
$color-purple-indicator: #597beb;

$color-black: #000000;
$color-gray-darker: #0a0b33;
$color-gray-dark: #1f1f1f;
$color-gray-lightest: #f1f1f1;
$color-gray-lighter: #e5e5e5;
$color-gray-light: #b4b4b4;
$color-gray-semi-light: #9e9e9e;
$color-gray-label: #6f7070;
$color-border-gray: #e9eceb;

/* 
* Main Colors defined in the brand
*/

$color-bright-blue: #2e74f3;
$color-soft-blue: #4ac3f6;
$color-light-blue: #2f80ed;
$color-dark-cyan: #27ae60;
$color-bright-yellow: #e2b93b;
$color-white: #ffffff;
$color-soft-red: #eb5757;
$color-black: #000000;
$color-light-black: #1d1d1d;
$color-lightest-black: #282828;
$color-darker-gray: #333333;
$color-dark-gray: #4f4f4f;
$color-gray: #828282;
$color-light-gray: #bdbdbd;
$color-lightest-gray: #e0e0e0;
$color-darkest-gray: #092c4c;
$color-azure-gray: #f7f8f8;
$color-gray-mid: #c4c4c4;

/* Branding color */
$brand-primary: $color-bright-blue;
$brand-success: $color-dark-cyan;
$brand-info: $color-soft-blue;
$brand-warning: $color-bright-yellow;
$brand-danger: $color-soft-red;

$gray-base: $color-black;
$bright-blue-lighter: rgba(46, 116, 243, 0.04);
$bright-blue-light: rgba(46, 116, 243, 0.08);
$gray-darker: lighten($gray-base, 32%);
$gray-dark: lighten($gray-base, 20%);
$gray: lighten($gray-base, 33.5%);
$gray-light: lighten($gray-base, 46.7%);
$gray-lighter: lighten($gray-base, 93.5%);

/* ==========================================================================
Functional colors
========================================================================== */

$background-color-base: $color-white;
$main-content-background-color: $color-gray-lighter;
$font-color-base: $color-darkest-gray;
$font-color-secondary: $color-dark-gray;
$component-background-color--active: $color-darker-gray;
$component-divider-color: $color-darker-gray;
$component-text-inactive: $color-darker-gray;
$preload-box-background-color: $color-darker-gray;
$background-color-primary: rgba($color-bright-blue, 0.04);
/* 
*  Links
*/

$link-color--primary: $brand-primary;
$link-color--primary--hover: darken($brand-primary, 30%);
$link-color--primary--active: lighten($brand-primary, 30%);

/* 
* Button
*/

$button-color--primary: $brand-primary;

$button-color--primary--hover: darken($brand-primary, 30%);
$button-color--primary--active: lighten($brand-primary, 30%);

$button-color--secondary: $color-white;

$button-color--secondary--hover: lighten($color-light-blue, 30%);
$button-color--secondary--active: lighten($color-light-blue, 40%);
$button-color--secondary-bdr: $brand-primary;
$button-color--secondary-bdr--hover: darken($color-light-blue, 30%);
$button-color--secondary-bdr--active: lighten($color-light-blue, 30%);

$button-color--tertiary: $color-white;

$button-color--tertiary--hover: lighten($color-light-blue, 30%);
$button-color--tertiary--active: lighten($color-light-blue, 40%);
$button-color--tertiary-bdr: 0;
$button-color--tertiary-bdr--hover: darken($color-light-blue, 30%);
$button-color--tertiary-bdr--active: lighten($color-light-blue, 30%);

$button-color--disabled: $color-lightest-gray;

/* 
drop shadow
*/
$drop-shadow--primary: rgba($color-black, 0.102);
