/* stylelint-disable */
@import '../../styles/import';
@import '../../styles/main';

.user {
  &__management {
    width: 100%;
    padding: 64px;
    @include break(map-get($grid-breakpoints, sm)) {
      padding: 16px;
     }
  }

  &__btn {
    display: flex;
    align-items: center;
    // font-size: 14px;
    font-weight: 500;
    line-height: 1.57143;
    text-transform: capitalize;
    letter-spacing: 0em;
    min-width: 64px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    width: fit-content;
    padding: 9px 16px;
    background-color: rgb(63, 89, 228);
    color: rgb(255, 255, 255);

    .button__text {
      font-size: 14px;
    }
  }

  &__heading {
    margin-bottom: 10px;
  }

  &__create {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    h1 {
      font-size: 2rem;
      line-height: 1.25;
      letter-spacing: 0.02em;
      font-weight: 500;
      color: black;
    }
  }

  &__search {
    height: 40px;
    display: flex;
    gap: 5px;
    @include break(map-get($grid-breakpoints, sm)) {
      flex-direction: column;
      gap: 8px;
      &__input{
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;}
    }
   
    
      
  
    // input[type="text"]{ padding: 20px 10px; line-height: 28px; }

    &__input {
      flex: 60;
      // width: 100%;
      border-radius: 6px;
      border: 1px solid grey;
      background: url(../../assets/search.png) no-repeat scroll 5px 9px;
      padding-left: 32px;
      background-size: 20px;
    }

    &__select {
      flex: 30;
      width: 100%;
      border-radius: 6px;
      border: 1px solid grey;
      background-color: none;
      // border: 1px solid #2e74f3;
    }

    &__reset {
      border: 1px solid rgb(215, 215, 215);

      .button__text {
        color: black;
      }

      .button__icon {
        color: black;
      }
    }
  }

  &__table {
    &__pagination {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      select{
        
        @include break(map-get($grid-breakpoints, sm)) {
          // display: none;
         }
      }
    }

    &__click {
      text-align: center;
      flex: 0 0 auto;
      overflow: visible;
      background-color: rgb(255, 255, 255);
      border-color: rgb(215, 215, 215);
      color: rgb(25, 25, 25);
      border-style: solid;
      border-width: 1px;

      .button__icon {
        height: 17px;
        width: 14px;
        color: rgb(25, 25, 25);
      }
    }
  }


  &__table{
    overflow-x:auto;
    @include break(map-get($grid-breakpoints, sm)) {
     margin-top: 100px;
    }
    table {
      width: 100%;
      padding: 1em;
      margin: 20px 0;
      border-collapse: collapse;
      // box-shadow: 0 1px 2px 0 #c6e8c9;
    }
    
    tr {
      height: 50px;
      border-bottom: 1px solid #80808059;
    }
    
    thead {
      // background-color: #caf0ec;
      font-size: 16px;
    
      tr {
        color: #191919;
        font-weight: 500;
      }
    }
    
    tbody {
      tr {
        color: #191919;
        font-weight: 400;
        font-size: 14px;
      }
    }
    
    th {
      text-align: left;
      padding: 10px 8px 14px 8px;
    }
    
    td {
      text-align: left;
      padding: 10px 8px 14px 8px;
    }
    
  }

  &__not_found {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;

  }
  &__not_found_wrapper {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    
    h1 {
      font-weight: 700;
      font-size: 2rem;
    }
  }
  .rc-pagination {
    display: flex;
    li {
      height: 4rem;
      width: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-item {
      border: none;
      font-family: $font-commissioner;
      font-weight: map-get($font-weights, 'semi-bold');
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-right: 0;
      border-radius: 0.6rem;
      a {
        color: $color-gray;
      }
    }
    &-item-active {
      text-decoration: underline;
      text-decoration-color: $brand-primary;
      a {
        color: $brand-primary;
      }
    }
  }

  &__actions {
    position: relative;

    &__prompt {
      position: absolute;
      top: 20px;
      left: -110px;
      width: 130px;
      height: max-content;
      z-index: 9;
      padding: 1.5rem;
      border-radius: 4px;
      background: white;
    }
    &__hidden {
      display: none;
    }
    &__options_pop {
      padding: 0.5rem 0;
      cursor: pointer;
      font-weight: 500;
    }
  }
  &__invite {
    width: 80vw;
    @include break(map-get($grid-breakpoints, sm)) {
     width: 95vw;
    }
  }
  &__invite_h1 {
      font-size: 1.6rem !important;
      font-weight: 700 !important;
    
  }
}

.user_role_form {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0px;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    flex: 1;
    gap: 20px;
  }

  &__row_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 2rem 0;
  }

  &__label {
    font-weight: map-get($font-weights, 'bold');
    font-size: map-get($font-sizes, 'mini');
    font-size: 2rem;
  }

  &__select_wrapper {
    min-width: 230px;
  }
}


.search-by-control {
  background-color: none;
  border-style: none;
  border-color: grey;
  border-width: 1px;
}

.padding-dp {
  padding: 0.8rem 0.5rem;
  border-color: lightgrey;
}

.arrow_container {
  justify-self: flex-end;
  
  button {
    cursor: pointer;
  }
}

