@import '../../styles/import';

.dd-cont {
  position: relative;
  @include flex($dis: inline-block);
  min-width: 22rem;
  width: 100%;
  font-family: $font-commissioner;
  // background-color: rgba($color-bright-blue, 0.04);
  // border: 1px solid $color-gray-lighter;
  border-radius: 0.5rem;

  &__selection-box {
    max-height: 4.4rem;
    padding: 0 1rem;
    @include flex($dis: flex, $jc: space-between, $ai: center);
    border-radius: 0.6rem;
    background-color: $color-gray-lightest;
    cursor: pointer;
  }

  &__selection-text {
    font-size: 1.6rem;
    width: 100%;
    color: $color-gray-darker;
    @include flex($dis: flex, $dir: row, $ai: center);

    &-child1 {
      margin-right: 1rem;
      font-weight: map-get($font-weights, 'medium');
      line-height: map-get($line-heights, 'mini');
      overflow-x: auto;
      white-space: nowrap;
    }
    &-child2 {
      margin: 0 1rem;
      color: $color-gray-label;
      font-size: map-get($font-sizes, 'micro');
      font-weight: map-get($font-weights, 'light');
      line-height: map-get($line-heights, 'mini');
      overflow-x: auto;
      white-space: nowrap;
    }
  }

  &__icon {
    display: block;
    height: 1.6rem;
    width: 1.6rem;

    &--flip {
      transform: rotate(180deg);
    }
  }
}

.config {
  &__btn {
    font-weight: map-get($font-weights, 'semi-bold');
    margin-right: 2rem;
  }
  &__btn-borderless {
    border: none !important;
    font-size: 1.6rem;
    margin: 1rem 0 0 0;
    font-weight: map-get($font-weights, 'semi-bold');
  }
  &__btn-container {
    display: flex;
    justify-content: center;
    // border-top: 1px solid #e0e0e0;    
  }

  &__select {
    padding: 1rem 0;
    // overflow: hidden;

    @include break(934px) {
      position: fixed;
      left: 2.4rem;
      top: 9.4rem;
    }

    @include respond(tab-portrait) {
      left: 1rem;
      top: 8rem;
    }

    .select__option-label {
      font-weight: map-get($font-weights, 'medium');
      width: 30%;
      overflow: auto;
    }

    .select__option-link {
      width: 60%;
      overflow: auto;
    }
  }
  &__flex_end {
    justify-content: flex-end;
  }
  &__heading {
    display: flex;
    align-items: center;
    color: $color-black;
    font-weight: map-get($font-weights, 'medium');
    font-size: map-get($font-sizes, 'smaller');
    padding: 1.8rem 2rem;
    // border-bottom: 1px solid #e0e0e0;
  }
  &__selection-box {
    background: rgba(46, 116, 243, 0.04) !important;
    padding: 0 1.5rem !important;
    border: 1px solid #ececec;
  }
  &__link {
    margin-left: 5px;
    color: $link-color--primary;
    border-bottom: 1px solid $link-color--primary;
    background-color: #fff;
    cursor: pointer;
  }
  &__flex_1 {
    flex: 1;
  }
  &__border_bt {
    border-bottom: 1px solid #e0e0e0;
  }
}

.config_modal {
  font-size: 1.8rem;
  background-color: $color-white;
  padding: 2.4rem 4rem;
  flex-direction: column;
  border-radius: 0.4rem;
  border: 1px solid $color-lightest-gray;
  &__header {
    margin-bottom: 2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7vh;
  }
  &__body {
    height: 67vh;
  }
  &__footer {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_container {
    border: 1px solid rgb(224, 224, 224);
    padding: 2rem;
    margin-top: 1rem;
    height: 37vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2.4rem;
    margin-bottom: 2rem;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }
  &__buttons--footer {
    display: flex;
    justify-content: center;
    gap: 2.4rem;
  }
  &__row_container {
    overflow-y: auto;
    min-height: 23vh;
  }
  &__select {
    width: 24%;
    margin-bottom: 2.5rem;
  }
  &__pattern {
    width: 66%;
    position: relative;
    top: -3px;
    .input__field {
      background: none !important;
    }
  }

  &-dropdown {
    // width: 70% !important;
    height: 5.5rem;
    min-width: -moz-fit-content;
    min-width: fit-content;
    border: none;
    background-color: transparent;

    .dd-cont__selection-box {
      border: 1px solid #bdbdbd;
      border-radius: 4px;
      height: 5rem;
      max-height: 5rem;
    }
    .dd-cont__selection-text span {
      font-weight: 400;
    }
  }

  &__icon--delete {
    margin-top: 3rem;
  }
  &__button {
    padding: 0;
    width: 13.6rem;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    font-weight: map-get($font-weights, 'bold');
  }
  &__icon-box {
    display: flex;
    height: 3rem;
  }

  &__icon {
    width: 5rem;
  }

  &__toggle_container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  &__toggle {
    display: flex;
    margin-top: 2rem;
    width: 12%;
    justify-content: space-between;
  }

  &__toggle_input {
    width: 50%;
  }

  &__label {
    font-weight: map-get($font-weights, 'semi-bold');
    font-size: map-get($font-sizes, 'mini');
  }

  /* Media Queries */

  @include break(map-get($grid-breakpoints, xl)) {
    &__row_container {
      height: 23vh;
    }
  }
}

.setting_header {
  font-weight: 700;
  font-size: 1.6rem;
  margin-top: 20px;
}

.height-mx-cnt {
  height: max-content !important;
}

.web-form {
  &__org_dropdown {
    border: 1px solid lightgray;
    width: max-content;
    max-width: 400px;
  }
}

.p-y-18 {
  padding: 18px 0px;
}