@import '../../styles/import';

.side-nav {
  width: 11rem;
  background-color: transparent;
  // display: flex;
  // flex-direction: column;
  position: sticky;
  z-index: 10;
  top: calc(7rem + 2.4rem);
  height: calc(100vh - 7rem - 2.4rem);

  @include break(map-get($grid-breakpoints, lg)) {
    display: none;
  }

  &__capitalize {
    text-transform: capitalize;
    padding-top: 4px;
  }

  &__nav {
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__list-item {
    @include flex($dis: flex, $dir: column, $ai: center);
    position: relative;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__button-label {
    font-weight: map-get($font-weights, 'semi-bold');
    font-size: $nav-button-font-size;
    line-height: map-get($line-heights, 'nano');
    color: $color-gray-label;
  }

  &__profile-menu-container {
    min-width: 16rem;
    position: absolute;
    left: 100%;
    bottom: 0;
    background-color: $color-white;
    border-radius: 0.8rem;
    padding-top: 1rem;
    box-shadow: 0px 4px 24px rgba($color-black, 0.1);
  }

  &__profile-menu {
    list-style: none;
  }

  &__profile-item {
    background-color: $color-white;
    padding: 0.7rem 0;
  }

  &__profile-item--hover {
    transition: background-color 200ms ease-out;
    &:hover {
      background-color: $bright-blue-lighter;
    }
  }

  &__profile-child {
    font-family: $font-commissioner;
    color: $color-gray-label;
    font-weight: map-get($font-weights, 'medium');
    font-size: map-get($font-sizes, 'micro');
    line-height: map-get($line-heights, 'mini');
    height: 100%;
    width: 100%;
    padding: 0.5rem 1.8rem;
    text-align: left;
    background-color: transparent;
  }

  &__profile-child--btn {
    cursor: pointer;
    color: $color-light-blue;
    font-size: map-get($font-sizes, 'mini');
  }
}
