$base-font-size: 1.6rem;

$line-heights: (
  nano: 1.5rem,
  micro: 2rem,
  mini: 2.2rem,
  smallest: 2.6rem,
  smaller: 2.8rem,
  small: 2.8rem,
  medium: 3.6rem,
  big: 4.4rem,
  bigger: 5.2rem,
  biggest: 6.2rem
);

$font-sizes: (
  nano: 1.2rem,
  micro: 1.4rem,
  mini: 1.6rem,
  smallest: 1.8rem,
  smaller: 2rem,
  small: 2.4rem,
  medium: 3.2rem,
  big: 4rem,
  bigger: 4.8rem,
  biggest: 5.6rem
);

$font-weights: (
  bold: 700,
  semi-bold: 600,
  medium: 500,
  normal: 400,
  light: 200
);

$button-font-size: 1.6rem;
$nav-button-font-size: 1.2rem;
$action-header-font-size: 2.4rem;

$base-line-height: map-get($line-heights, big);
