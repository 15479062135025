@import '../../styles/import';

.select {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 5.6rem;
  left: 0;
  // min-width: 150%;
  width: 100%;
  white-space: nowrap;
  color: $color-bright-blue;

  border: 1px solid $color-gray-lightest;
  box-shadow: 0px 0.4rem 2.4rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  padding: 1.8rem 0rem 0rem 0rem;
  z-index: 2;

  &--bottom {
    top: auto;
  }

  &--open {
    opacity: 1;
    visibility: visible;
    background-color: white;
  }
  &--close {
    display: none;
  }

  &--active {
    background-color: $color-white;
  }

  &__option-label {
    text-align: left;
  }
  // &__option-label {
  //   font-weight: map-get($font-weights, 'medium');
  //   width: 30%;
  //   overflow: scroll;
  //   margin-right: 5rem;
  // }
  // &__option-link {
  //   width: 50%;
  //   overflow: scroll;
  // }
  &--active &__option-label {
    background-color: $color-white;
  }

  &__options {
    max-height: 40rem;
    overflow-y: auto;
  }

  &__option {
    padding: 0.5rem 1.8rem;
    display: flex;
  }

  &__input {
    display: none;
  }

  &__label {
    @include flex($dis: flex, $ai: center);
    // gap: 16rem;
    cursor: pointer;
    width: 84%;
  }

  &__marker {
    @include flex($dis: inline-flex);
    height: 1rem;
    width: 1rem;
    border: 1px solid $color-primary-dark;

    &--checkbox {
      border-radius: 3px;
    }

    &--radio {
      border-radius: 100px;
    }
  }

  &__option-text {
    @include flex($dis: flex, $ai: center, $jc: flex-start);
    grid-gap: 20px;
    width: 100%;
    font-family: $font-commissioner;
    font-size: 1.6rem;
    font-weight: map-get($font-weights, 'normal');
    line-height: map-get($line-heights, 'smaller');
    color: $color-gray-darker;
  }

  &__option-text-multi {
    justify-content: flex-start;
    margin-left: 3rem;
  }

  &__input + &__label &__marker {
    background-color: $brand-primary;
  }

  &__input:checked + &__label &__marker {
    background-color: $color-white;
  }

  &__option-icon {
    max-height: 4rem;
    width: 10%;
    display: flex;
    justify-content: center;

    svg {
      padding-top: 1rem;
      max-height: 4rem;
    }
  }

  &__tooltiptext {
    visibility: hidden;
    background-color: $color-black;
    color: $color-white;
    border-radius: 0.6rem;
    padding: 0.5rem;
    font-size: map-get($font-sizes, 'nano');
    line-height: map-get($line-heights, 'micro');
    position: absolute;
    z-index: 1;
    top: -3rem;
    left: 10rem;
    max-height: 5rem;
    overflow: hidden;
    max-width: 28rem;
    white-space: normal;
    overflow-wrap: break-word;
  }

  &__tooltip {
    margin-left: 1rem;
    position: relative;
    display: inline-block;
    &:hover {
      .select__tooltiptext {
        visibility: visible;
      }
    }
  }

  &__tooltip-option-label {
    position: absolute;
    width: 15rem;
    bottom: -1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__tooltip-option-link {
    position: absolute;
    width: 20rem;
    left: 20rem;
    bottom: -1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include break(map-get($grid-breakpoints, sm)) {
    &__tooltip-option-link {
      left: 14rem;
    }
  }
}
