@import '../../styles/import';

.nav-button {
  width: 80%;
  padding: 0.6rem 0;
  background-color: transparent;
  color: $color-black;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  -webkit-tap-highlight-color: transparent;

  &__icon {
    display: block;
    height: 3rem;
    width: 100%;
  }

  &__label {
    font-family: $font-sans-pro;
    font-weight: map-get($font-weights, 'semi-bold');
    font-size: $nav-button-font-size;
    line-height: map-get($line-heights, 'nano');
    color: $color-gray-label;
    margin: 5px 10px;
    text-transform: capitalize;
    text-align: center;
  }
}

.nav-button-active {
  background-color: $bright-blue-light;
  border-radius: 4px;

  .nav-button__label {
    color: $color-gray-darker;
  }
}
