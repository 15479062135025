@import '../import';

body {
  font-family: $font-commissioner;
  font-size: map-get($font-sizes, 'mini');
  line-height: map-get($line-heights, 'mini');
}

.--flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.--flex-horizontal-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.--flex-horizontal-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.--center {
  text-align: center;
}

.--bold {
  font-weight: bold;
}
.--clr {
  clear: both;
}

.--pad-margin-0 {
  margin: 0;
  padding: 0;
}

.--relative {
  position: relative;
}

.--no-bullets {
  ul {
    list-style: none;
  }
}
