@import '../../../src/styles/import';

.footer {
  margin-top: 5rem;
  width: 100%;
  justify-content: space-between;
  font-family: $font-commissioner;

  &__container {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
  }

  &__link {
    text-decoration: none;
    color: $link-color--primary;
    position: relative;
  }
  &__text {
    font-style: normal;
    font-weight: map-get($font-weights, 'normal');
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 0.5rem;
    color: $color-gray;
  }

  &__text--italic {
    font-style: italic;
  }

  &__left-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__right-section {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 50%;
  }
  &__text--spaced {
    padding-left: 3.2rem;
  }
  &__socials {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
  &__socials--spaced {
    margin-left: 1rem;
  }

  &__icon__container__social {
    padding: 0.96rem 0.8rem;
  }

  &__icon__social {
    width: 3.2rem;
    height: 2.8rem;
    border-radius: 50%;
    border: 1px solid $color-azure-gray;
    background-color: $color-azure-gray;
  }

  &__terms {
    display: flex;
    justify-content: flex-end;
  }

  &__gap {
    display: flex;
    justify-content: flex-end;
    visibility: hidden;
  }

  /* -----------  MEDIA QUERIES --------------- */

  @include break(map-get($grid-breakpoints, sm)) {
    & {
      flex-direction: column-reverse;
    }
    &__left-section {
      width: 100%;
    }
    &__right-section {
      width: 100%;
      align-items: flex-start;
      margin-top: 2rem;
    }
    &__text--spaced {
      padding-left: 0;
    }

    &__terms {
      margin-top: 2rem;
      flex-direction: column;
    }
  }

  @include break(map-get($grid-breakpoints, xs)) {
    & {
      position: relative;
      left: initial;
      bottom: initial;
      padding: 0 0px;
      margin-top: 0px;
    }
  }
}
