@import '../../styles/import';

.dashboard-skeleton {
  &__top-row {
    display: flex;
    margin-top: 1.4rem;
    gap: 1.5rem;
    height: 25.3rem;

    @include respond(tab-landscape) {
      flex-direction: column;
      height: auto;
    }
  }

  &__charts {
    flex: 5 1 0;
    background-color: $color-white;

    @include respond(tab-landscape) {
      flex: 0 0 24.8rem;
    }

    @include break(992px) {
      flex: 0 0 49.3rem;
    }
  }

  &__links {
    flex: 2 1 0;
    background-color: $color-white;

    @include respond(tab-landscape) {
      flex: 0 0 25.2rem;
    }
  }

  &__bottom-row {
    display: flex;
    gap: 1.5rem;
    margin-top: 1.8rem;
    margin-bottom: 2.4rem;
    height: 48rem;

    @include respond(tab-landscape) {
      flex-direction: column;
      height: auto;
    }
  }

  &__info-table {
    flex: 1 1 0;
    background-color: $color-white;

    @include respond(tab-landscape) {
      flex: 0 0 47rem;
    }
  }

  &__heading {
    padding: 2rem 2.4rem;
    border-bottom: 1px solid $color-lightest-gray;
    font-size: map-get($font-sizes, 'small');
    font-weight: map-get($font-weights, 'medium');
    margin-bottom: 2rem;

    &::before {
      content: url('../../assets/title_triangle.svg');
      position: relative;
      bottom: -5px;
      left: -4px;
    }
  }

  &__content {
    padding: 2rem 2.4rem;
  }

  &__dummy {
    display: inline-block;
    height: 2.4rem;
    width: 100%;
    transition: background-image 200ms ease-out;
    // background-color: $color-gray-mid;
    background-image: linear-gradient(to right, rgba($color-gray-mid, 0.4) 0%, transparent 100%);
    position: relative;

    &--mid {
      width: 50%;
    }

    &--small {
      width: 36%;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(
        to right,
        transparent 0%,
        rgba($color-gray-mid, 0.6) 50%,
        transparent 100%
      );
      background-size: 40rem;
      background-repeat: no-repeat;
      animation: pulsate 2s linear infinite;
    }
  }
}

@keyframes pulsate {
  0% {
    background-position: -40rem 0;
  }

  100% {
    background-position: 100vw 0;
  }
}
