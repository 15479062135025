@import '../../styles/import';

.ah-cont {
  position: sticky;
  top: 0;
  z-index: 30;
  padding: 0 2.7rem;
  background-color: $color-white;
  height: 7rem;

  @include respond(tab-portrait) {
    padding: 0 1rem;
  }

  &__header {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__domain {
    display: flex;
    align-items: center;
    position: relative;
    gap: 3rem;
    max-width: 690px;

    @include respond(tab-portrait) {
      gap: 1rem;
    }
  }

  &__divider {
    flex: 0 0 1px;
    background-color: $color-lightest-gray;
    align-self: stretch;
  }

  &__icon {
    height: 4rem;
    width: 4rem;

    display: block;
  }

  &__dropdown {
    width: 100%;
    .input__field {
      height: 4.4rem;
      font-size: 1.4rem;
      border-radius: 0.6rem;
      padding: 0 1.4rem;
    }
  }

  &__dropdown-textfield {
    min-width: 6rem !important;
  }

  &__text {
    font-family: $font-commissioner;
    font-weight: map-get($font-weights, 'semi-bold');
    font-size: $action-header-font-size;
    line-height: map-get($line-heights, 'bigger');
    color: $color-gray-dark;
  }

  &__btn-loader-grp {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  &__status-msg {
    font-family: $font-commissioner;
    font-style: normal;
    font-weight: map-get($font-weights, 'normal');
    font-size: map-get($font-sizes, 'micro');
    line-height: map-get($line-heights, 'micro');
    display: flex;
    align-items: center;
    text-align: right;
    color: $color-soft-red;
  }

  &__status-icon {
    display: flex;
    height: 2.4rem;
    width: 2.4rem;
    margin: 0.5rem 0.3rem 0;
  }

  &__btn-grp {
    @include respond(mobile) {
      display: none;
    }
  }

  &__status {
    color: $brand-primary;
    display: flex;
    align-items: center;
  }

  /*---LOADING TYPE ---*/

  &_spin {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    font-size: 0;
    color: $brand-success;
    width: 26px;
    height: 26px;
  }

  &_square {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;

    -webkit-animation: ball-square-clockwise-spin 3s infinite ease-in-out;
    -moz-animation: ball-square-clockwise-spin 3s infinite ease-in-out;
    -o-animation: ball-square-clockwise-spin 3s infinite ease-in-out;
    animation: ball-square-clockwise-spin 3s infinite ease-in-out;
  }
  &_square-one {
    top: 0;
    left: 0;
    -webkit-animation-delay: -0.875s;
    -moz-animation-delay: -0.875s;
    -o-animation-delay: -0.875s;
    animation-delay: -0.875s;
  }
  &_square-two {
    top: 0;
    left: 50%;
    -webkit-animation-delay: -0.75s;
    -moz-animation-delay: -0.75s;
    -o-animation-delay: -0.75s;
    animation-delay: -0.75s;
  }
  &_square-three {
    top: 50%;
    left: 50%;
    -webkit-animation-delay: -0.5s;
    -moz-animation-delay: -0.5s;
    -o-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  &_square-four {
    top: 50%;
    left: 0;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
  }

  @keyframes ball-square-clockwise-spin {
    0% {
      opacity: 0.1;
    }
    40% {
      opacity: 0.3;
    }

    70% {
      opacity: 0.7;
    }

    100% {
      opacity: 0;
    }
  }
  /*---LOADING TYPE END---*/
}

.vertical-divider {
  left: 7rem;
}

.switch-icon {
  &__label {
    position: absolute;
    // height: 2rem;
    left: -120%;
    bottom: -100%;
    font-size: 1rem;
    background: white;
    padding: 4px;
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    text-transform: capitalize;
    min-width: 100px;
    text-align: center;
  }
  &__hidden {
    display: none;
  }
}

.flex {
  display: flex;
}

.gap-10 {
  gap: 10px
}