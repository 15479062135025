@import '../../styles/import';

.rc-tabs {
  width: 100%;
  &-nav-list {
    border-bottom: 0 !important;
    margin: 0;
    display: flex;
    gap: 2rem;
  }
  &-tab {
    min-height: 6rem;
    display: inline-flex;
    align-items: center;
    border: 0;
    border-bottom: 0;
    margin: 0 1rem;
    color: $color-darker-gray;
    opacity: 0.8;
    font-weight: 500;
    font-size: 1.6rem;
    cursor: pointer;
  }
  &-tab:first-child {
    margin-left: 3rem;
  }

  &-tab-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &-tab-btn {
    margin-bottom: -0.5rem;
  }
  &-tab-active {
    border-bottom: 4px solid $brand-primary;
    color: $brand-primary;
    opacity: 1;
    font-weight: 600;
  }
  &-nav {
    // position: sticky;
    // top: 9.4rem;
    // z-index: 20;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ececec;
    box-shadow: 0px 2px 5px $drop-shadow--primary;
    border-radius: 4px;
    background: #ffffff;

    @include respond(tab-portrait) {
      top: 8rem;
    }
  }
  // &-content-holder {
  //   border-radius: 4px;
  //   box-shadow: 0px 2px 5px $drop-shadow--primary;
  //   margin-top: 1rem;
  // }
}
