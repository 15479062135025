@import '../../styles/import';

.input {
  &__container {
    position: relative;
  }

  &__label {
    font-weight: map-get($font-weights, 'bold');
    font-size: map-get($font-sizes, 'mini');
  }

  &__sub-label {
    float: right;
    color: $color-gray;
    font-size: map-get($font-sizes, 'micro');
    font-weight: map-get($font-weights, 'light');
  }

  &__field {
    width: 100%;
    margin: 0.8rem 0 0;
    outline: none;
    padding: 0.8rem;
    padding-right: 3rem;

    background-color: $color-white;
    height: 6rem;
    border: 1px solid $color-light-gray;
    box-sizing: border-box;
    border-radius: 0.4rem;
    font-style: normal;
    font-weight: map-get($font-weights, 'normal');
    font-size: 1.6rem;
    line-height: 2.24rem;

    font-family: $font-commissioner;
    padding-left: 2rem;

    ::placeholder {
      color: $color-light-gray;
    }
  }

  &__field-area {
    width: 100%;
    margin: 0.8rem 0;
    outline: none;
    padding: 0.8rem;
    padding-right: 3rem;

    background-color: $color-white;

    border: 1px solid $color-light-gray;
    box-sizing: border-box;
    border-radius: 0.4rem;
    font-style: normal;
    font-weight: map-get($font-weights, 'normal');
    font-size: 1rem;
    line-height: 2.24rem;

    font-family: $font-commissioner;
    padding-left: 2rem;

    ::placeholder {
      color: $color-light-gray;
    }
  }

  &__icon__container {
    position: absolute;
    right: 2rem;
    top: 2rem;
    padding: 0.96rem 0.8rem;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
  }

  &__status {
    color: $color-gray-semi-light;
    font-size: 1.4rem;
  }

  &__type-warning {
    border-color: $brand-warning;
  }

  &__status-warning {
    color: $brand-warning;
  }

  &__type-success {
    border-color: $brand-success;
  }

  &__status-success {
    color: $brand-success;
  }

  &__type-error {
    border-color: $brand-danger;
  }
  &__status-error {
    color: $brand-danger;
  }

  &__size-small {
    height: 5rem !important;
  }
}

input:disabled {
  cursor: not-allowed;
}
